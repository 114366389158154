import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, link }) => {
	return (
		<a href={link} className="button">
			{children}
			<svg
				className="icon"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M5 12h13M12 5l7 7-7 7" />
			</svg>
		</a>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	link: PropTypes.string.isRequired,
};

export default Button;
