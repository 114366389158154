import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, lang, meta, keywords, title, image }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						social {
							creator
							image
						}
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	const metaImage = image || site.siteMetadata.social.image;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={site.siteMetadata.title}
			titleTemplate={title ? `%s | ${defaultTitle}` : defaultTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: site.siteMetadata.title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `og:image`,
					content: metaImage,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.creator || "",
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:image`,
					content: metaImage,
				},
			]
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `),
						  }
						: []
				)
				.concat(meta)}
		/>
	);
};

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
	description: ``,
	title: ``,
	image: ``,
};

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	image: PropTypes.string,
};

export default Seo;
