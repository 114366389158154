import React from "react";

export const Logo = () => (
	<svg width="200" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.6977 31.7204v6.7542c0 4.129.3486 6.3804 2.7268 6.3804 2.3782 0 2.5873-2.1766 2.5873-6.3804V.890381h11.6818V37.9513c0 11.9299-4.4078 17.629-14.1994 17.629s-14.1297-5.6243-14.1297-17.629v-6.2309h11.3332Z"
			fill="#F9FFF8"
		/>
		<path
			d="M21.6977 31.7204v6.7542c0 4.129.3486 6.3804 2.7268 6.3804 2.3782 0 2.5873-2.1766 2.5873-6.3804V.890381h11.6818V37.9513c0 11.9299-4.4078 17.629-14.1994 17.629s-14.1297-5.6243-14.1297-17.629v-6.2309h11.3332Z"
			fill="#fff"
			fillOpacity=".85"
		/>
		<path
			d="M52.2662.890381h13.7811L75.2812 54.5336H63.7388l-.9761-7.0533h-7.2042l-.9761 7.0533H43.04L52.2662.890381ZM62.1275 37.6522l-2.0296-18.9084c-.2788-2.3262-.488-4.7272-.6274-7.2776h-.6275c-.1394 3.0738-.4183 5.4748-.6275 7.2776l-1.9598 18.9084h5.8718Z"
			fill="#F9FFF8"
		/>
		<path
			d="M52.2662.890381h13.7811L75.2812 54.5336H63.7388l-.9761-7.0533h-7.2042l-.9761 7.0533H43.04L52.2662.890381ZM62.1275 37.6522l-2.0296-18.9084c-.2788-2.3262-.488-4.7272-.6274-7.2776h-.6275c-.1394 3.0738-.4183 5.4748-.6275 7.2776l-1.9598 18.9084h5.8718Z"
			fill="#fff"
			fillOpacity=".85"
		/>
		<path
			d="M79.8976.890381h13.0839L97.1801 19.3419c.6275 2.7748 1.0458 5.1758 1.255 7.1281h.6274c.2092-2.7.6972-5.0262 1.1925-7.2028L104.516.890381H117.6V54.5336h-10.001v-24.159c0-2.7.14-5.4.419-7.9505h-.837l-5.74 32.1095h-5.3841l-5.7325-32.1095h-.8366c.2789 2.6253.4183 5.3253.4183 7.9505v24.159H79.9053V.890381h-.0077Z"
			fill="#F9FFF8"
		/>
		<path
			d="M79.8976.890381h13.0839L97.1801 19.3419c.6275 2.7748 1.0458 5.1758 1.255 7.1281h.6274c.2092-2.7.6972-5.0262 1.1925-7.2028L104.516.890381H117.6V54.5336h-10.001v-24.159c0-2.7.14-5.4.419-7.9505h-.837l-5.74 32.1095h-5.3841l-5.7325-32.1095h-.8366c.2789 2.6253.4183 5.3253.4183 7.9505v24.159H79.9053V.890381h-.0077Z"
			fill="#fff"
			fillOpacity=".85"
		/>
		<path
			d="M125.433.890381h13.084l4.198 18.451519c.628 2.7748 1.046 5.1758 1.255 7.1281h.628c.209-2.7.697-5.0262 1.193-7.2028L150.059.890381h13.084V54.5336h-10.001v-24.159c0-2.7.14-5.4.418-7.9505h-.836l-5.74 32.1095H141.6l-5.733-32.1095h-.836c.279 2.6253.418 5.3253.418 7.9505v24.159h-10.001V.890381h-.015Z"
			fill="#F9FFF8"
		/>
		<path
			d="M125.433.890381h13.084l4.198 18.451519c.628 2.7748 1.046 5.1758 1.255 7.1281h.628c.209-2.7.697-5.0262 1.193-7.2028L150.059.890381h13.084V54.5336h-10.001v-24.159c0-2.7.14-5.4.418-7.9505h-.836l-5.74 32.1095H141.6l-5.733-32.1095h-.836c.279 2.6253.418 5.3253.418 7.9505v24.159h-10.001V.890381h-.015Z"
			fill="#fff"
			fillOpacity=".85"
		/>
		<path
			d="M176.985.890381h13.781L200 54.5336h-11.542l-.976-7.0533h-7.205l-.976 7.0533h-11.542L176.985.890381Zm9.869 36.761819-2.029-18.9084c-.279-2.3262-.489-4.7272-.628-7.2776h-.627c-.14 3.0738-.419 5.4748-.628 7.2776l-1.96 18.9084h5.872Z"
			fill="#F9FFF8"
		/>
		<path
			d="M176.985.890381h13.781L200 54.5336h-11.542l-.976-7.0533h-7.205l-.976 7.0533h-11.542L176.985.890381Zm9.869 36.761819-2.029-18.9084c-.279-2.3262-.489-4.7272-.628-7.2776h-.627c-.14 3.0738-.419 5.4748-.628 7.2776l-1.96 18.9084h5.872Z"
			fill="#fff"
			fillOpacity=".85"
		/>
		<path
			d="M7.20429 26.005c3.97881 0 7.20431-3.4592 7.20431-7.7263s-3.2255-7.7262-7.20431-7.7262C3.22547 10.5525 0 14.0116 0 18.2787c0 4.2671 3.22547 7.7263 7.20429 7.7263Z"
			fill="#EF7D81"
		/>
	</svg>
);
