import React from "react";
import PropTypes from "prop-types";

import "../styles/style.css";

const Layout = ({ children }) => {
	return (
		<>
			<a href="#content" className="skip-link visually-hidden">
				Skip to main content
			</a>

			<div id="site-container" className="site-container">
				<main id="content" className="main-content">
					{children}
				</main>
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
