import * as React from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import { Logo } from "../components/Logo";
import Seo from "../components/Seo";
import { Triangle } from "../components/Triangle";

const IndexPage = ({ location }) => {
	return (
		<Layout location={location}>
			<Seo />
			<div className="content rhythm">
				<Logo />
				<p>
					Career-boosting jams hosted by the biggest studios in games
					&amp; immersive.
				</p>
				<Button link="https://qxkcbfguirb.typeform.com/to/AKOgZXHR">
					Get early access
				</Button>
			</div>
			<div className="background">
				<Triangle className="tri-small" />
				<Triangle className="tri-large" />
			</div>
		</Layout>
	);
};

export default IndexPage;
