import React from "react";

export const Triangle = ({ className }) => {
	return (
		<svg
			className={className}
			width="560"
			height="510"
			viewBox="0 0 560 510"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.1"
				d="M280 484.711L0.434752 459.421L162.109 230.061L323.763 0.934967L441.674 255.351L559.565 510L280 484.711Z"
				fill="#77C4F6"
			/>
		</svg>
	);
};
